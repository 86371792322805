<script>
  import { _ } from "svelte-i18n";
  import { startup_state, keywords_store, animation_finished, loading_keywords, is_tag_array } from "$lib/store.js";

  $: keywords = $keywords_store;
  let dropdownOpen = false;
  let activeDropdown = null;

  function deleteKeyword(keyword) {
    keywords_store.update((keywords_old) => {
      return keywords_old.filter((t) => t.displayText !== keyword.displayText);
    });
  }

  function clearKeywords() {
    keywords_store.update(() => {
      return [];
    });
    keywords = keywords_store;
    document.getElementById("search-input").focus();
  }

  function flip(keyword) {
    if (!$startup_state) {
      keywords_store.update((existing_keywords) => {
        return existing_keywords.map((t) => {
          if (t.displayText === keyword.displayText) {
            t.incl_excl = keyword.incl_excl;
          }
          return t;
        });
      });
    }
  }

  function keywordState(keyword) {
    if (keyword.incl_excl === "INCLUDE") {
      // Update keywordClasses store reactively
      if (!$startup_state) {
        return "included";
      } else {
        return "inactive included";
      }
    } else if (keyword.incl_excl === "EXCLUDE") {
      if (!$startup_state) {
        return "excluded";
      } else {
        return "inactive excluded";
      }
    } else {
      return "";
    }
  }

  function dropdownItemState(keyword, state) {
    if (state == keywordState(keyword)) {
      return state;
    } else {
      return "";
    }
  }

  function toggleDropdown(keyword) {
    // Close all other dropdowns before toggling the current one
    keywords = keywords.map((d) => {
      if (d !== keyword) {
        return { ...d, dropdownOpen: false };
      }
      return d;
    });
    keyword.dropdownOpen = !keyword.dropdownOpen;

    if (keyword.dropdownOpen) {
      activeDropdown = keyword;
      // Add document click listener when dropdown opens to close dropdown on click outside
      setTimeout(() => {
        document.addEventListener("pointerdown", closeDropdown);
        document.addEventListener("scroll", closeDropdown);
      }, 0);
    } else {
      document.removeEventListener("pointerdown", closeDropdown);
      document.removeEventListener("scroll", closeDropdown);
    }
    // keywords_store.update(() => {
    //   return keywords;
    // });
  }

  function closeDropdown() {
    if (activeDropdown === null) {
      return;
    } else {
      activeDropdown.dropdownOpen = false;
      keywords_store.update(() => {
        return keywords;
      });
      activeDropdown = null;
      document.removeEventListener("pointerdown", closeDropdown);
      document.removeEventListener("scroll", closeDropdown);
    }
  }

  function getDropdownPosition(keywordID) {
    const keywordElement = document.getElementById(keywordID);
    if (keywordElement) {
      const parentRect = keywordElement.getBoundingClientRect();
      const left = parentRect.left;
      const top = parentRect.bottom;
      return `left: ${left}px; top: ${top + 4}px;`;
    }
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="keywords" class="keywords">
  {#if $animation_finished}
    {#each keywords as keyword}
      <div class="keyword-unit {$startup_state && $is_tag_array}" id={keyword.displayText}>
        <div
          class="keyword {keywordState(keyword)}"
          class:fade-bg={$startup_state && $is_tag_array}
          role="button"
          appearance="none;"
          tabindex="0"
          aria-expanded={dropdownOpen}
          aria-haspopup="true"
          on:pointerdown={(event) => {
            event.stopPropagation();
          }}
          on:pointerdown={() => toggleDropdown(keyword)}
          on:keypress={() => toggleDropdown(keyword)}>
          {keyword.displayText}
        </div>
        {#if keyword.dropdownOpen && !$startup_state}
          <div class="dropdown" style={getDropdownPosition(keyword.displayText)}>
            <div
              class="dropdown-item {dropdownItemState(keyword, 'included')}"
              role="button"
              tabindex="0"
              on:pointerdown={(event) => {
                event.stopPropagation();
              }}
              on:pointerup={(event) => {
                keyword.incl_excl = "INCLUDE";
                closeDropdown();
                flip(keyword);
                event.stopPropagation();
              }}
              on:keydown={() => {
                closeDropdown();
              }}>
              {dropdownItemState(keyword, "included") == "included" ? $_("included") : $_("include")}
            </div>
            <div
              class="dropdown-item {dropdownItemState(keyword, 'excluded')}"
              role="button"
              tabindex="0"
              on:pointerdown={(event) => {
                event.stopPropagation();
              }}
              on:pointerup={(event) => {
                keyword.incl_excl = "EXCLUDE";
                closeDropdown();
                flip(keyword);
                event.stopPropagation();
              }}
              on:keydown={() => {
                closeDropdown();
              }}>
              {dropdownItemState(keyword, "excluded") == "excluded" ? $_("excluded") : $_("exclude")}
            </div>
          </div>
        {/if}
        {#if !$startup_state == true}
          <span
            class="delete-keyword"
            role="button"
            tabindex="0"
            on:click={() => deleteKeyword(keyword)}
            on:keypress={() => deleteKeyword(keyword)} />
        {/if}
      </div>
    {/each}
    {#if $loading_keywords}
      <div class="loader" />
    {/if}
    {#if keywords.length > 0 && !$startup_state == true}
      <div class="keyword-unit">
        <span
          class="clear-keywords"
          role="button"
          tabindex="0"
          on:click={() => clearKeywords()}
          on:keypress={() => clearKeywords()} />
      </div>
    {/if}
  {/if}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .keywords {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding-bottom: 22px;
    margin-left: 8px;
    margin-right: 8px;
    width: 90%;
    /* transition: all 0.25s ease-in-out; */
    box-sizing: border-box;
    /* overflow: hidden; */
  }
  .keyword-unit {
    display: flex;
    gap: 3px;
    margin: 4px;
    -webkit-animation-fill-mode: both !important;
    animation-fill-mode: both !important;
  }
  .keyword {
    align-items: center;
    border-radius: 12px;
    color: var(--white);
    display: flex;
    padding: 0.5rem 1rem;
    align-self: center;
    white-space: nowrap;
    user-select: none;
    border: 1px solid transparent;
  }
  .keyword:hover {
    border: 1px solid var(--white);
    cursor: pointer;
  }
  .keyword.included {
    background-color: var(--solid-blue);
    /* border: 1px solid var(--solid-blue); */
  }
  .keyword.excluded {
    background-color: var(--bright-red);
    /* border: 1px solid var(--bright-red); */
    text-decoration: line-through;
  }
  .keyword.inactive {
    opacity: 0.6;
    cursor: auto;
  }
  .keyword.inactive:hover {
    border: 1px solid transparent;
  }
  .fade-bg {
    background-color: transparent;
    transition: all 2s;
  }
  .delete-keyword {
    background-image: url("/buttons/btn_delete_keyword.svg");
    background-position: center;
    background-repeat: no-repeat;
    color: var(--white);
    cursor: pointer;
    padding-right: 10px;
    width: 32px;
    height: 32px;
    margin-left: -12px;
    align-self: center;
  }
  .delete-keyword:hover {
    background-image: url("/buttons/btn_delete_keyword_hover.svg");
  }
  .clear-keywords {
    background-image: url("/buttons/btn_clear_keywords.svg");
    background-repeat: no-repeat;
    background-size: 60px;
    cursor: pointer;
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: 10px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  .clear-keywords:hover {
    background-image: url("/buttons/btn_clear_keywords_hover.svg");
  }
  .dropdown {
    z-index: 999;
    padding: 10px 0px;
    position: fixed;
    background-color: var(--white-grey);
    border: 1px solid var(--solid-grey);
    border-radius: 12px;
  }
  .dropdown-item {
    padding: 10px 40px;
    background-size: 36px;
    background-position: 8px 1px;
    background-repeat: no-repeat;
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item.included {
    color: var(--solid-blue);
    background-image: url("/icons/icon_check_include.svg");
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item.excluded {
    color: var(--bright-red);
    background-image: url("/icons/icon_check_exclude.svg");
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item:hover {
    background-color: var(--white);
  }
  .loader {
    background-image: url("/icons/icon_bouncing-circles.svg");
    width: 50px;
    height: 52px;
    align-self: center;
    margin: 0 0 0 0;
  }

  @media (max-width: 480px) {
    .keywords {
      padding-bottom: 8px;
      margin-left: 0px;
      margin-right: 0px;
      width: 96%;
    }
  }
</style>
