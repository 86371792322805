<script>
  import {
    keywords_store,
    startup_state,
    animation_finished,
    random_button_initiated,
    loading_keywords,
    is_tag_array,
    call_reduce_search,
    call_collapse_moods
  } from "$lib/store.js";
  import { _ } from "svelte-i18n";
  import Keywords from "$lib/Keywords.svelte";
  import { PUBLIC_API_BASE_URL } from "$env/static/public";
  import { goto } from "$app/navigation";
  import { getTitles } from "$lib/search.js";
  import { onMount } from "svelte";
  // import Bubble from "./Bubble.svelte";
  import KeywordInspiration from "$lib/KeywordInspiration.svelte";

  export let cache;
  export let samplePrompt;

  $animation_finished = false;
  let prompt = "";
  let index = 0;
  let intervalId;
  let initialRandomButton;

  let searchDiv;
  let searchElements;
  let searchComponent;
  let SearchInputHasFocus = false;
  let keywordsDiv;
  let keywordInspirations;
  // let keywordInspiration1;
  let searchFieldContainer;
  let searchElementsRect;
  let claim;
  let reducing = false;
  let reduced = false;
  let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  call_reduce_search.subscribe((action) => {
    if (action) {
      reduceSearch();
      call_reduce_search.set(false);
    }
  });

  // keywords_store.subscribe((action) => {
  //   if (action && searchDiv) {
  //     expandSearch();
  //   }
  // });

  $: showClearPrompt = prompt != "";
  $: showAddKeywordsBtn = SearchInputHasFocus && prompt != "";
  $: keywordsStoreIsEmpty = $keywords_store.length <= 0;

  $: if (keywordsStoreIsEmpty) {
    prompt = "";
  }

  const typeChar = () => {
    if (index < samplePrompt.length && !$animation_finished) {
      prompt += samplePrompt[index];
      index += 1;
    } else {
      setTimeout(() => {
        $animation_finished = true;
        showClearPrompt = true;
        index = 999;
        clearInterval(intervalId);
      }, 1000); // 1 second delay
    }
  };

  function startTypeAnimation() {
    clearKeywords();
    SearchInputHasFocus = true;
    samplePrompt = $_(`sample_prompt${Math.trunc(Math.random() * 70) + 1}`);
    index = 0;
    $animation_finished = false;
    clearInterval(intervalId);
    intervalId = setInterval(typeChar, 20);
  }
  startTypeAnimation();

  $: if ($animation_finished) {
    getKeywords(samplePrompt);
    SearchInputHasFocus = false;
  }

  $: if ($keywords_store.length > 0) {
    getTitles();
  }

  let validationError = "";
  const maxTotalLength = 256;
  const maxWordLength = 42;

  function validate(question) {
    const totalLength = question.replace(/\s+/g, "").length;
    if (totalLength > maxTotalLength) {
      validationError = $_("error_description_too_long");
      return false;
    }

    // forbid strange characters
    const validSentence = /^[\p{L}\d\s.,;:'"!?><&%$+=@*/-]+$/u;
    if (!validSentence.test(question)) {
      validationError = $_("error_not_a_valid_sentence");
      return false;
    }

    const wordsArray = question.split(/\s+/);
    if (wordsArray.some((word) => word.length > maxWordLength)) {
      validationError = $_("error_words_cannot_be_too_long");
      return false;
    }

    // forbid words that only contain punctuation
    const realWords = /^[^\p{L}\d]+$/u;
    const isSinglePunktuation = /^[,:;./'"><%$&*+=-]$/;
    if (wordsArray.some((word) => realWords.test(word) && !isSinglePunktuation.test(word))) {
      validationError = $_("error_words_need_letters");
      return false;
    }

    validationError = "";
    return true;
  }

  function sanitizePrompt(question) {
    question = question.normalize();
    question = question.trim();
    question = question.replace(/\s+([.,;:!?])/g, "$1");
    question = question.replace(/\s+/g, " ");
    return question;
  }

  $is_tag_array = false;
  function fadeKeywords() {
    if (!$is_tag_array && !$random_button_initiated) {
      setTimeout(() => {
        $is_tag_array = true;
        initialRandomButton = true;
        localStorage.setItem("random_button_initiated", JSON.stringify(true));
      }, "2000");
    }
  }

  async function getKeywords(question) {
    if (reduced) {
      expandSearch();
    }

    $loading_keywords = true;
    window._paq.push(["trackEvent", "prompt", "submitted", question]);
    let keywords = cache[question];
    if (!keywords) {
      keywords = await getKeywordsFromAPI(question);
    }
    updateKeywords(keywords);
    $loading_keywords = false;
    index = 999;
  }

  async function getKeywordsFromAPI(question) {
    question = sanitizePrompt(question);
    if (question != "" && validate(question)) {
      const endpoint = "/api/search/getTagsFromPrompt";
      const url = `${PUBLIC_API_BASE_URL}/${endpoint}/${encodeURIComponent(question)}`;
      let response_json;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch keywords");
        }
        response_json = await response.json();
        return response_json.data.tags;
      } catch (err) {
        console.error(err.message);
        goto("/error");
      }
    }
  }

  function updateKeywords(new_keywords) {
    keywords_store.update((keywords) => {
      // Map guarantees unique entries and acts as a set: (JS has no comaparator for Set)
      const keywordsMap = new Map(keywords.map((keyword) => [keyword.displayText, keyword]));
      // add new keywords, overwrite existing ones:
      new_keywords.forEach((k) => {
        keywordsMap.set(k.displayText, k);
      });
      return Array.from(keywordsMap.values());
    });
    fadeKeywords();
  }

  function clearKeywords() {
    keywords_store.update(() => {
      return [];
    });
  }

  function keypressed(e) {
    if (e.key === "Enter") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      getKeywords(prompt);
    }
  }

  function clearPrompt() {
    prompt = "";
    document.getElementById("search-input").focus();
    showClearPrompt = false;
  }

  function SearchInputClicked() {
    call_collapse_moods.set(true);
    // workaround for iPhone scrolling up when keyboard slides in
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 300); // 300ms delay to let the keyboard fully open

    clearInterval(intervalId);
    if (prompt != "") {
      showClearPrompt = true;
    }
    index = 999;
    if ($startup_state) {
      prompt = "";
      clearKeywords();
      let claimHeight = 0;
      if (claim) {
        claimHeight = claim.scrollHeight;
      }
      keywordInspirations.style.height = `${keywordInspirations.scrollHeight - claimHeight}px`;
      changeStartupState(false);
    }

    initialRandomButton = false;
    updateSearchComponentHeight();
  }

  function handleSearchInputFocus() {
    SearchInputHasFocus = true;
    if ($keywords_store.length <= 0) {
      prompt = "";
    }
  }

  function handleSearchInputBlur() {
    if (isIOS && prompt != "") {
      getKeywords(prompt);
    }
    expandSearch();
    setTimeout(() => {
      SearchInputHasFocus = false;
    }, 250);
  }

  function changeStartupState(b) {
    if ($startup_state) {
      startup_state.update(() => {
        return b;
      });
    }
  }

  function getRandomPrompt() {
    initialRandomButton = false;
    prompt = "";
    validationError = "";
    window._paq.push(["trackEvent", "random search", "clicked"]);
    SearchInputClicked();
    startTypeAnimation();
  }

  const updateSearchComponentHeight = () => {
    const currentHeight = `${searchElements.offsetHeight}px`;
    searchComponent.style.height = currentHeight;
  };

  function reduceSearch() {
    reducing = true;
    call_collapse_moods.set(true);
    searchDiv.style.position = "sticky";
    searchDiv.style.top = "var(--header-height)";
    searchElements.style.height = searchElements.scrollHeight + "px";

    keywordInspirations.style.height = `0px`;
    keywordInspirations.style.top = `-${keywordInspirations.scrollHeight}px`;
    keywordsDiv.style.height = `0`;
    keywordsDiv.style.paddingBottom = `0`;
    const scrolledUpHeight = `${searchFieldContainer.clientHeight}px`;
    searchElements.style.height = scrolledUpHeight;
    searchComponent.style.height = scrolledUpHeight;

    setTimeout(() => {
      // console.log("reduceSearch window.scrollY: " + window.scrollY);
      if (window.scrollY <= 0) {
        window.scrollTo({ top: 1 });
      }
      reduced = true;
      reducing = false;
    }, 250);
  }

  function expandSearch() {
    searchDiv.style.position = "relative";
    searchDiv.style.top = "auto";

    keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
    keywordInspirations.style.top = `0px`;
    keywordsDiv.style.height = ``;
    keywordsDiv.style.paddingBottom = `18px`;
    const scrolledDownHeight = `${searchFieldContainer.scrollHeight + keywordsDiv.scrollHeight + keywordInspirations.scrollHeight}px`;
    searchElements.style.height = scrolledDownHeight;
    searchComponent.style.height = scrolledDownHeight;

    setTimeout(() => {
      searchElements.style.height = `auto`;
      reduced = false;
      reducing = false;
    }, 250);
  }

  const checkScroll = () => {
    if (reducing) return;
    searchElementsRect = searchElements.getBoundingClientRect(); // check if necessary?

    if (
      window.scrollY > 0 &&
      searchElementsRect.bottom + searchElementsRect.height / 2 <= window.innerHeight &&
      !reduced
    ) {
      reduceSearch();
    } else if (window.scrollY <= 0 && reduced) {
      expandSearch();
    }
  };

  onMount(() => {
    clearKeywords();
    // changeStartupState(true);
    searchDiv = document.querySelector("#search");
    searchElements = document.getElementById("search-elements");
    searchComponent = document.querySelector("#search-component");
    keywordsDiv = document.querySelector("#keywords");
    keywordInspirations = document.getElementById("keyword-inspirations");
    searchFieldContainer = document.querySelector("#search-field-container");
    claim = document.querySelector("#claim");
    searchElementsRect = searchElements.getBoundingClientRect();
    updateSearchComponentHeight();

    const observer = new MutationObserver(updateSearchComponentHeight);
    observer.observe(searchElements, { attributes: true, childList: true, subtree: true, attributeFilter: ["style"] });

    searchElements.addEventListener("transitionend", updateSearchComponentHeight);
    window.addEventListener("scroll", checkScroll);
    // window.addEventListener("resize", checkScroll);
    return () => {
      observer.disconnect();
      searchElements.removeEventListener("transitionend", updateSearchComponentHeight);
      window.removeEventListener("scroll", checkScroll);
      // window.removeEventListener("resize", checkScroll);
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="search-component">
  <div id="search-wrap">
    <div id="search-elements">
      <!-- <KeywordInspiration /> -->
      <KeywordInspiration />
      <!-- <KeywordInspiration bind:this={keywordInspiration1} /> -->
      <div id="search-field-container">
        <div class="search-field" id="bubbleAnchor">
          <input
            id="search-input"
            bind:value={prompt}
            placeholder={$_("search-prompt")}
            on:keyup|preventDefault={(event) => {
              if (event.key === "Enter") {
                // Trigger on "Enter" only
                keypressed(event);
                event.target.blur(); // Remove focus to close keyboard on mobile (iPhone)
              } else {
                SearchInputHasFocus = true;
              }
            }}
            on:click|preventDefault={SearchInputClicked}
            on:focus={handleSearchInputFocus}
            on:blur={handleSearchInputBlur} />
          {#if showClearPrompt}
            <span
              class="clear-prompt"
              role="button"
              tabindex="0"
              on:click|preventDefault={() => clearPrompt()}
              on:keypress|preventDefault={() => clearPrompt()} />
          {/if}
          {#if showAddKeywordsBtn}
            <span
              role="button"
              tabindex="0"
              title={$_("add-search-term")}
              on:click|preventDefault={() => getKeywords(prompt)}
              on:keypress|preventDefault={() => getKeywords(prompt)}>
              <img class="keyword-button" alt={$_("add-search-term")} />
            </span>
          {/if}
        </div>
        {#if !showAddKeywordsBtn}
          <span
            role="button"
            style="display: inline-block; width:60px; height:60px"
            tabindex="0"
            title="random search"
            on:click|preventDefault={() => getRandomPrompt()}
            on:keypress|preventDefault={() => getRandomPrompt()}>
            <img
              class={initialRandomButton ? "random-search-button initial" : "random-search-button"}
              alt="random search" />
          </span>
        {/if}
      </div>

      {#if validationError}
        <div class="error">{validationError}</div>
      {/if}
      <Keywords />
      <!-- <Keywords {isTagArray} {loadingKeywords} /> -->
    </div>
  </div>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .error {
    margin-bottom: 5px;
    color: var(--bright-red);
  }
  #search-component {
    top: var(--header-height);
    border-radius: 36px;
    background-image: radial-gradient(
        circle at 82px -100px,
        rgb(20, 137, 205) 12px,
        rgba(4, 76, 118, 1) 48px,
        rgba(4, 76, 118, 0.1) 180px,
        rgba(4, 76, 118, 0) 240px
      ),
      linear-gradient(
        110deg,
        rgb(23, 37, 56) 180px,
        rgb(19, 29, 46) 73%,
        /*rgba(113, 32, 79, 1) 90%  ,*/ rgb(43, 21, 46) 100%
      );
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    width: 94%;
    margin-bottom: 4px;
    transition: height 0.25s ease-in-out;
  }
  #search-wrap {
    display: flex;
    width: 100%;
  }
  #search-elements {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    /* transition: height 0.25s ease-in-out; */
  }
  #search-field-container {
    display: flex;
    justify-content: center;
    width: 45em;
    max-width: 94%;
    padding-top: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  .search-field {
    background: url("/icons/icon_search.svg") no-repeat scroll 0px 0px;
    background-color: rgba(181, 229, 255, 0.2);
    border: 1px solid var(--black);
    border-radius: 12px;
    height: 60px;
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
  }
  .search-field:hover {
    border: 1px solid var(--white);
    cursor: pointer;
  }
  #search-input {
    outline: none;
    box-sizing: border-box;
    color: var(--white);
    font-size: 16px;
    height: 100%;
    padding-left: 3.4em;
    width: 100%;

    background: transparent;
    border: 1px transparent;
  }
  .clear-prompt {
    content: url("/buttons/btn_clear_prompt.svg");
    width: 38px;
    height: 38px;
    align-self: center;
    margin-right: 4px;
  }
  .clear-prompt:hover {
    content: url("/buttons/btn_clear_prompt_hover.svg");
  }
  .keyword-button {
    content: url("/buttons/btn_add_keywords.svg");
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 60px;
    height: 60px;
  }
  .keyword-button:hover {
    content: url("/buttons/btn_add_keywords_hover.svg");
    cursor: pointer;
  }
  .random-search-button {
    content: url("/buttons/btn_random_search.svg");
    border: 1px solid var(--black);
    border-radius: 12px;
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 2px 0;
    box-sizing: border-box;
  }
  .random-search-button.initial {
    content: url("/buttons/btn_random_search_initial.svg");
  }
  .random-search-button:hover {
    content: url("/buttons/btn_random_search_hover.svg");
    border: 1px solid var(--white);
    cursor: pointer;
  }
  ::placeholder {
    color: var(--white-50);
    opacity: 1;
  }
  @media (max-width: 480px) {
    #search-component {
      width: 100%;
      border-radius: 0px;
    }
    .search-field {
      background: url(/icons/icon_search.svg) no-repeat scroll -10px 0px;
      background-color: rgba(181, 229, 255, 0.2);
      max-width: 96%;
    }
    #search-field-container {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    #search-input {
      padding-left: 2.5em;
    }
    .clear-prompt {
      margin-right: 0;
      margin-left: -4px;
    }
  }
</style>
