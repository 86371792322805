<script>
  import { _ } from "svelte-i18n";
  import { goto } from "$app/navigation";
  import { selected_streamers, startup_state, call_reduce_search } from "$lib/store.js";
  import { onMount } from "svelte";
  import { PUBLIC_API_BASE_URL } from "$env/static/public";
  import { getTitles } from "$lib/search.js";
  import streamerIcons from "$lib/SVGs.js";
  import { slide } from "svelte/transition";

  let selected = [];
  let streamers = [];
  let showDropdown = false;

  let streamersComponent;
  let streamersComponentRect;
  let streamersDropdown;
  let searchComponent;
  let searchComponentRect;

  $: if (streamersDropdown) checkAndScroll();
  let checkLowerButtons;

  async function getStreamers() {
    const endpoint = "/api/info/streamerList"; //GET
    const params = "/CH"; //hardcoded for now
    // TODO: activate the following line when the streamers have the correct region
    // const params = `/${$settings_store.region}`;
    const url = PUBLIC_API_BASE_URL + endpoint + params;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch streamers");
      }
      const response_json = await response.json();
      return response_json.data.streamers;
    } catch (err) {
      console.error(err.message);
      goto("/error");
    }
  }

  function toggleStreamer(streamer) {
    console.log("toggleStreamer:" + streamer.name);
    window._paq.push(["trackEvent", "streamer", "clicked", `${streamer.name};`]);
    if (selected.length === streamers.length) {
      selected = [streamer];
    } else if (selected.length === 1 && selected.find((s) => s.id === streamer.id)) {
      selected = streamers;
    } else if (selected.find((s) => s.id === streamer.id)) {
      selected = selected.filter((s) => s.id !== streamer.id);
    } else {
      selected = [...selected, streamer];
    }
  }

  function toggleDropdown() {
    showDropdown = !showDropdown;
    // if (!showDropdown && $selected_streamers.length != selected.length) {
    if (!showDropdown && $selected_streamers != selected) {
      selected_streamers.set(selected);
      console.log("getTitles");
      getTitles();
    }
    if ($startup_state) {
      startup_state.set(false);
    }
  }

  function closeDropdown() {
    showDropdown = false;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function reset() {
    selected = streamers;
  }

  function checkAndScroll() {
    setTimeout(() => {
      streamersComponentRect = streamersComponent.getBoundingClientRect();
      searchComponentRect = searchComponent.getBoundingClientRect();
      // Check if the bottom of targetDiv is below the viewport height
      if (
        streamersComponentRect &&
        streamersComponent &&
        streamersComponentRect.top + streamersComponentRect.height + window.scrollY > window.innerHeight
      ) {
        // if (streamersComponent) {
        call_reduce_search.set(true);
        window.scrollTo({
          top: 1,
          behavior: "smooth"
        });
        // }
      }
    }, 250);
  }

  function checkScroll() {
    if (streamersComponentRect && streamersComponentRect.top - searchComponentRect.height - window.scrollY < 0) {
      checkLowerButtons = true;
    } else {
      checkLowerButtons = false;
    }
  }

  onMount(() => {
    searchComponent = document.getElementById("search-component");
    // Load streamers and set selected_streamers
    getStreamers().then((s) => {
      streamers = s;
      selected = s;
      selected_streamers.set(s);
    });
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="streamers-component" bind:this={streamersComponent}>
  <div
    id="streamers-button"
    class="{showDropdown ? 'open ' : ''}{selected.length !== streamers.length ? 'active' : ''}"
    role="button"
    tabindex="0"
    on:click={() => toggleDropdown()}
    on:keypress={() => toggleDropdown()}>
    <span class="streamers-heading">{$_("streamers_label")}</span>
    <div class="streamers-thumbs">
      {#each streamers as streamer}
        {#if selected.some((s) => streamer.id === s.id)}
          <img class="streamer-thumb" alt={streamer.name} src={streamerIcons[streamer.name]} width="1" height="1" />
        {/if}
      {/each}
    </div>
  </div>
  {#if showDropdown}
    <div id="streamers-dropdown" transition:slide bind:this={streamersDropdown}>
      <div class="streamers-header">
        <h3>{$_("streamers_headline")}</h3>
        <div class="buttons">
          {#if selected.length !== streamers.length}
            <button title={$_("reset")} class="reset-button" on:click={reset} on:keypress={reset} />
          {/if}
          {#if selected.length !== $selected_streamers.length}
            <button title="ok" class="ok-button" on:click={toggleDropdown} on:keypress={toggleDropdown} />
          {:else}
            <button title="close" class="close-button" on:click={closeDropdown} on:keypress={closeDropdown} />
          {/if}
        </div>
      </div>
      <div class="streamers">
        {#each streamers as streamer}
          <div
            role="button"
            tabindex="0"
            class="streamer {selected.some((s) => streamer.id === s.id && selected.length !== streamers.length)
              ? 'selected'
              : ''}{selected.some((s) => streamer.id === s.id && selected.length === streamers.length)
              ? 'initial'
              : ''}"
            on:click={() => toggleStreamer(streamer)}
            on:keypress={() => toggleStreamer(streamer)}>
            <img class="streamer-icon" alt={streamer.name} src={streamerIcons[streamer.name]} width="1" height="1" />
            <span class="streamer-name">{streamer.name}</span>
            <img
              src={selected.find((s) => s.id === streamer.id && selected.length !== streamers.length)
                ? "/icons/icon_checkmark.svg"
                : "/icons/icon_plus.svg"}
              alt="arrow icon"
              width="24px"
              height="38px" />
          </div>
        {/each}
      </div>
      {#if checkLowerButtons}
        <div class="buttons">
          {#if selected.length !== streamers.length}
            <button title={$_("reset")} class="reset-button" on:click={reset} on:keypress={reset} />
          {/if}
          {#if selected.length !== $selected_streamers.length}
            <button title="ok" class="ok-button" on:click={toggleDropdown} on:keypress={toggleDropdown} />
          {:else}
            <button title="close" class="close-button" on:click={closeDropdown} on:keypress={closeDropdown} />
          {/if}
        </div>
      {/if}
    </div>
  {/if}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .streamers-component {
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    justify-content: center;
    position: relative;
    width: 94%;
    margin-top: 12px;
    align-self: center;
  }
  #streamers-button {
    display: flex;
    border-radius: 8px;
    border: 1px solid var(--white-50);
    color: var(--white-50);
    cursor: pointer;
    background-image: url("/icons/icon_arrow_down.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 24px center;
    padding: 18px 28px 18px 50px;
    box-sizing: border-box; /* Include padding in the element's total width calculation */
    gap: 8px;
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.25s;
  }
  #streamers-button:hover {
    border: 1px solid var(--white);
    color: var(--white);
    background-image: url("/icons/icon_arrow_down_hover.svg");
  }
  #streamers-button.open {
    /* border: 1px solid transparent; */
    /* color: var(--white); */
    background-color: var(--dark-grey);
    background-image: url("/icons/icon_arrow_up.svg");
  }
  #streamers-button.open:hover {
    background-image: url("/icons/icon_arrow_up_hover.svg");
    border: 1px solid var(--white);
  }
  #streamers-button.active {
    background-image: url("/icons/icon_arrow_down_active.svg");
    background-color: var(--lightest-blue);
    color: var(--black);
  }
  #streamers-button.open.active {
    background-image: url("/icons/icon_arrow_up_active.svg");
  }
  .streamers-heading {
    flex: 0 0 auto;
    white-space: nowrap;
  }
  .streamers-thumbs {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    grid-template-columns: repeat(auto-fit, minmax(4px, 1fr));
    align-items: left;
    margin-left: 8px;
    padding-right: 8px;
    flex: 1 1 auto;
    overflow: hidden;
    border-radius: 2px;
  }
  .streamer-thumb {
    width: 20px;
    height: 20px;
    margin-left: auto;
  }
  .streamers-header {
    display: flex;
    justify-content: space-between;
    margin-left: 18px;
    align-items: center;
  }
  h3 {
    color: var(--lightest-blue);
  }
  .buttons {
    display: flex;
    gap: 8px;
    margin: 8px 18px 18px;
    height: 40px;
    justify-content: flex-end;
  }
  .reset-button {
    background: url(/buttons/btn_reset.svg) no-repeat;
    display: block;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    border: none;
    position: relative;
    float: left;
    cursor: pointer;
  }
  .reset-button:hover {
    background: url(/buttons/btn_reset_hover.svg) no-repeat;
  }
  .ok-button {
    background: url(/buttons/btn_ok.svg) no-repeat;
    display: block;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    border: none;
    position: relative;
    float: left;
    cursor: pointer;
  }
  .ok-button:hover {
    background: url(/buttons/btn_ok_hover.svg) no-repeat;
  }
  .close-button {
    background: url(/buttons/btn_close_details.svg) no-repeat;
    display: block;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    border: none;
    position: relative;
    float: left;
    cursor: pointer;
  }
  .close-button:hover {
    background: url(/buttons/btn_close_details_hover.svg) no-repeat;
  }
  #streamers-dropdown {
    display: grid;
    background-color: var(--dark-grey);
    border-radius: 12px;
    width: 100%;
    margin-top: 4px;
    transition: height 0.25s;
  }
  .streamers {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 8px 8px;
    margin-top: 4px;
    margin-bottom: 18px;
    margin-left: 18px;
    margin-right: 18px;
  }
  .streamer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    background-color: var(--white-10);
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 4px;
    gap: 12px;
    cursor: pointer;
  }
  .streamer:hover,
  .streamer.initial:hover {
    border: 1px solid var(--white);
  }
  .streamer.selected {
    background-color: var(--light-blue);
    color: var(--black);
  }
  .streamer.initial {
    border: 1px solid var(--light-blue);
    background-color: var(--dark-grey);
  }
  .streamer-icon {
    width: 48px;
    height: 48px;
    border: 2px solid transparent;
    border-radius: 7px;
  }
  .streamer-name {
    flex: 1 1 auto; /* Allow this div to grow and fill the remaining space */
    text-align: left; /* Optional: Center the content inside the middle div */
  }

  @media (max-width: 480px) {
    .streamers {
      margin-top: 0px;
    }
  }
</style>
